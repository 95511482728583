interface collectionCardBgColorsType {
    bg: string;
    ac: string;
}

export const collectionCardBgColors: collectionCardBgColorsType[] = [
    { bg: "bg-mint-cream", ac: "bg-eucalyptus" },
    { bg: "bg-corn-silk", ac: "bg-gargoyle-gas" },
    { bg: "bg-azureish-white", ac: "bg-light-cobalt-blue" },
    { bg: "bg-lavender-blush", ac: "bg-congo-pink" },
];

export const questionText =
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?';

export const questionOptions = [
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?',
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?',
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?',
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?',
];

export const classNameOptions = [
    { label: "Select your grade", value: 0 },
    { label: "1st", value: 1 },
    { label: "2nd", value: 2 },
    { label: "3rd", value: 3 },
    { label: "4th", value: 4 },
    { label: "5th", value: 5 },
    { label: "6th", value: 6 },
    { label: "7th", value: 7 },
    { label: "8th", value: 8 },
];

export const genderOptions = [
    { label: "Select gender", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    // { label: "Others", value: "others" },
];

export const profileTypeOptions = [
    { label: "", value: "" },
    { label: "Principal", value: "principal" },
    { label: "Teacher", value: "teacher" },
    { label: "Owner", value: "owner" },
    { label: "Government official", value: "government_official" },
    { label: "Vice Principal", value: "vice_principal" },
    { label: "School Coordinator", value: "school_coordinator" },
    { label: "Parent", value: "parent" },
    { label: "Other", value: "other" },
];

export const gradeRangeOptions = [
    { label: "", value: "" },
    { label: "Above 90%", value: "above_90" },
    { label: "Between 85 and 90%", value: "between_85_and_90" },
    { label: "Between 70 and 85%", value: "between_70_and_85" },
    { label: "Below 70%", value: "below_70" },
];

export const languageOptions = [
    { label: "", value: "" },
    { label: "English", value: "english" },
];

export const competitionOptions = [
    { label: "Select Option", value: "" },
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
    { label: "Hmm, we want to", value: "hmm, we want to" },
];

export const PAGE_LOADING_BOTTLENECK_DELAY = 3000;
